<template>
  <footer v-if="!authPage()" :class="[{ 'bottom-footer-only': bottomFooterOnly() }, $config.platform]">
    <div class="container">
      <div class="row">
        <div class="left-side col col-3">
          <nuxt-link to="/" class="logo" :title="$config.platformName">
            <component :is="Logo" />
          </nuxt-link>
          <p class="h4">
            {{ $c.text('footer.text') }}
          </p>
          <SocialIcons />
        </div>
        <div
          class="right-side col offset-1"
        >
          <div class="row">
            <div
              v-for="(v, i) in menu" :key="i" class="content col col-3"
              :class="v.title.toLowerCase()"
            >
              <div>
                <p class="h6">
                  {{ v.title }}
                </p>
                <ul>
                  <li v-for="(link, k) in v.urls" :key="k" :class="[link.class , `link-${k}`]">
                    <a
                      v-if="link.external && $config.platform === 'fa'"
                      :href="link.url"
                      class="link"
                      :data-event="link.event"
                      data-direct-send
                    >
                      {{ link.title }}
                    </a>
                    <nuxt-link
                      v-else :to="link.url"
                      class="link"
                      :data-event="link.event"
                    >
                      {{ link.title }}
                    </nuxt-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="container">
        <div class="row">
          <div class="col-6 col">
            © {{ currentYear }} {{ $config.platformName }}. All Rights Reserved.
          </div>
          <div class="col-6 col text-right">
            <nuxt-link to="/sitemap/">
              Sitemap
            </nuxt-link>
            <nuxt-link to="/terms-of-use/">
              Terms of Use
            </nuxt-link>
            <nuxt-link to="/privacy-policy/">
              Privacy Policy
            </nuxt-link>
            <nuxt-link to="/cookie-policy/">
              Cookies
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import SocialIcons from './SocialIcons';

import {
  LANDING_PAGES, SELLABLE_COURSES, UPSELL_PAGES, AFFILIATE_PAGES, DISCOVER_PAGES, CAMPAIGN_PAGES,
} from '../utils/constants';

export default {
  components: {
    SocialIcons,
  },
  computed: {
    Logo() {
      if (this.$config.platform === 'fa') {
        return () => import('../assets/svg/365-fa-logo-extended.svg?vue-component');
      }
      return () => import('../assets/svg/365-logo-extended.svg?vue-component');
    },
    menu() {
      const cols = [
        {
          title: 'About',
          urls: [
            {
              title: 'About Us',
              url: '/about-us/',
              event: 'footer_about_us.click',
            },
            ...(this.$config.platform === 'ds' ? [{
              title: 'Meet the Instructors',
              url: '/instructors/',
              event: 'footer_instructors.click',
            },
            {
              title: 'Become an Instructor',
              url: '/become-a-data-science-instructor/',
              event: 'footer_become_instructor.click',
            }] : []),
            {
              title: 'Contact Us',
              url: '/contact-us/',
              event: 'footer_contact_us.click',
            },
            {
              title: 'Pricing',
              url: '/pricing/',
              event: 'footer_pricing.click',
            },
          ],
        },
        {
          title: 'Learn',
          urls: [
            {
              title: 'Courses',
              url: '/courses/',
              event: 'footer_courses.click',
            },
            {
              title: 'Career tracks',
              url: '/career-tracks/',
              event: 'footer_career_tracks.click',
            },
            {
              title: 'Career Certificate',
              url: '/career-track-certificate/',
              event: 'footer_career_certificate.click',
            },
            {
              title: 'Course Certificate',
              url: this.$config.platform === 'fa' ? '/certificate/' : '/course-certificate/',
              event: 'footer_course_certificate.click',
            },
          ],
        },
        {
          title: 'Resources',
          urls: [
            ...(this.$config.platform === 'ds' ? [
              {
                title: 'Course Notes',
                url: '/resources-center/course-notes/',
                event: 'footer_course_notes.click',
              },
              {
                title: 'Templates',
                url: '/resources-center/templates/',
                event: 'footer_templates.click',
              },
              {
                title: 'Career Guides',
                url: '/resources-center/career-guides/',
                event: 'footer_career_guides.click',
              },
              {
                title: 'Practice exams',
                url: '/resources-center/practice-exams/',
                event: 'footer_practice_exams.click',
              },
              {
                title: 'Calculators',
                url: '/calculators/confidence-interval-calculator/',
                event: 'footer_calculators.click',
              },
              {
                title: 'Flashcards',
                url: '/flashcards/',
                event: 'footer_flashcards.click',
              },
              {
                title: 'Blog',
                url: '/blog/',
                event: 'footer_blog.click',
                external: true,
              },
            ] : []),
            ...(this.$config.platform === 'fa' ? [
              {
                title: 'Templates',
                url: '/category/templates-and-models/',
                event: 'footer_templates_and_models.click',
                external: true,
              },
              {
                title: 'Course Notes',
                url: '/category/course-notes/',
                event: 'footer_course_notes.click',
                external: true,
              },
              {
                title: 'Cheat Sheets',
                url: '/category/cheat-sheets/',
                event: 'footer_cheat_sheets.click',
                external: true,
              },
              {
                title: 'Finance Data',
                url: '/category/data/',
                event: 'footer_finance_data.click',
                external: true,
              },
              {
                title: 'Infographics',
                url: '/category/infographics/',
                event: 'footer_infographics.click',
                external: true,
              },
              {
                title: 'Practice exams',
                url: '/category/practice/',
                event: 'footer_practice_exams.click',
                external: true,
              },
              // {
              //   title: 'Flashcards',
              //   url: '/flashcards/',
              //   event: 'footer_flashcards.click',
              // },
              {
                title: 'Knowledge Hub',
                url: '/category/knowledge-hub/',
                event: 'footer_knowledge_hub.click',
                external: true,
              },
              {
                title: 'Career Advice',
                url: '/category/career-advice/',
                event: 'footer_career_advice.click',
                external: true,
              },
            ] : []),
          ],
        },
        {
          title: 'Platform',
          urls: [
            ...(this.$config.platform === 'ds' ? [{
              title: 'Success stories',
              url: '/success-stories/',
              event: 'footer_career_success_stories.click',
            }] : []),
            {
              title: 'Q&A Hub',
              url: '/qa-hub/',
              event: 'footer_qa_hub.click',
            },
            {
              title: 'Help Center',
              url: '/support/',
              event: 'footer_help_center.click',
            },
            {
              title: 'Verify Certificate',
              url: '/verify-certificate/',
              event: 'footer_verify_certificate.click',
            },
          ],
        },
      ];

      return cols;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    authPage() {
      return [
        ...AFFILIATE_PAGES,
        ...LANDING_PAGES,
        ...UPSELL_PAGES,
        ...DISCOVER_PAGES,
        ...CAMPAIGN_PAGES,
      ].includes(this.$route.path) || this.$route.path.match(/\/(login|signup|checkout|redirecting)/) || this.$route.query.preview || this.$route.path === '/ty-downloadable-resources/' || this.$route.name === 'f-id';
    },
    bottomFooterOnly() {
      return (SELLABLE_COURSES.includes(this.$route.path) || DISCOVER_PAGES.includes(this.$route.path)) && this.$route.path !== '/ty-downloadable-resources/';
    },
  },
};
</script>
<style lang="scss">
footer {
  background-color: var(--main-dark);
  padding: var(--spacing-80) 0 0;
  color: var(--main-light);

  &.bottom-footer-only {
    padding: 0;

    > .container {
      display: none;
    }

    .bottom {
      margin-top: 0;
    }
  }

  .container {
    .left-side {
      > a {
        display: block;
        height: 38px;
        margin-bottom: var(--spacing-16);

        &.logo svg {
          height: 24px;
          margin-bottom: 10px;
        }
      }

      > p {
        margin-top: var(--spacing-18);
      }
    }

    .right-side {
      font-size: 18px;

      .content {
        .h6 {
          margin: 0 0 30px;
        }

        ul {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            a {
              display: block;
              line-height: 150%;
            }

            + li {
              margin-top: var(--spacing-16);
            }
          }
        }
      }
    }
  }

  .bottom {
    color: #7A808A;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: var(--spacing-60);

    .container {
      padding-top: 30px;
      padding-bottom: 30px;
      line-height: 150%;

      .text-right {
        text-align: right;
      }

      a {
        color: #7A808A;

        + a {
          margin-left: var(--spacing-36);
        }
      }
    }
  }
}

.fa {
  .bottom {
    border-top: 1px solid var(--accent-shade-3);
    color: var(--main-dark-light-4);

    .container a {
      color: var(--main-dark-light-4);
    }
  }

  .icons-social a svg path {
    fill: var(--main-light-shade-4);
  }
}

@include bp($bp-mobile) {
  footer {
    &.bottom-footer-only {
      .bottom {
        .container {
          .text-right {
            order: 1;
            margin-top: var(--spacing-16);
            margin-bottom: 0;
          }
        }
      }
    }

    .right-side {
      padding-top: 20px;

      .col.content {
        max-width: 50%;
        margin-top: 60px;
      }
    }

    .bottom {
      text-align: center;
      font-size: 14px;

      .container .text-right {
        order: -1;
        margin-bottom: var(--spacing-16);
        text-align: center;
      }
    }
  }
}
</style>
